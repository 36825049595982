import { getLog } from '@aurora/shared-utils/log';

const log = getLog(module);

/**
 * Retrieves the thumbnail URL of a YouTube video.
 * @param iframeString - The iframe string of the YouTube video.
 * @returns the URL of the video thumbnail.
 */
function getYoutubeVideoThumbnailUrl(iframeString: string): string {
  const regex = new RegExp('image=([^&]+)'); // Regular expression pattern used to extract the image URL from a string.
  const thumbnailUrlMatch = decodeURIComponent(iframeString).match(regex);

  if (!thumbnailUrlMatch) {
    log.error('No image URL found for the YouTube video.');
    return '';
  }
  const [, thumbnailUrl] = thumbnailUrlMatch;
  return thumbnailUrl;
}

/**
 * Retrieves the thumbnail URL of a Vimeo video.
 * @param iframeString - The iframe string of the YouTube video.
 * @returns The URL of the video thumbnail.
 */
function getVimeoVideoThumbnailUrl(iframeString: string): string {
  const imgUrlRegex = new RegExp('(?<=image=)[^&]+'); // Regular expression pattern to extract the image URL from a string.
  const imageUrl = decodeURIComponent(iframeString).match(imgUrlRegex);
  if (!imageUrl) {
    return '';
  }
  return imageUrl[0];
}

/**
 * Gets the thumbnail image source of the video
 * @param liveStreamUrl - The URL of the live stream.
 * @param iframeSource - The source URL of the iframe.
 * @returns The thumbnail image source of the video
 */
function getVideoThumbnailUrl(liveStreamUrl: string, iframeSource: string): string {
  const isYoutubeVideoRegex = new RegExp(
    '(?:https?://)?(?:www.)?(?:youtube.com|youtu.be)/(?:watch?v=)?(.+)'
  ); // Regular expression pattern to match YouTube video URLs.

  const isVimeoVideoRegex = new RegExp('(?:https?://)?(?:www.)?vimeo.com/(?:video/)?(.+)'); // Regular expression pattern to match Vimeo video URLs.
  const isFacebookVideoRegex = new RegExp('(?:https?://)?(?:www.)?facebook.com/(?:video/)?(.+)'); // Regular expression pattern to match Facebook video URLs.
  let thumbnailUrl: string = '';

  if (isYoutubeVideoRegex.test(liveStreamUrl)) {
    thumbnailUrl = getYoutubeVideoThumbnailUrl(iframeSource);
  } else if (isVimeoVideoRegex.test(liveStreamUrl)) {
    thumbnailUrl = getVimeoVideoThumbnailUrl(iframeSource);
  } else if (isFacebookVideoRegex.test(liveStreamUrl)) {
    thumbnailUrl = null;
  }
  return thumbnailUrl;
}

/**
 * Gets the original video url from the iframe string
 * @param iframeString - The iframe
 * @returns the url of the video
 */
function getVideoUrlFromIframe(iframeString: string): string {
  const regex = /url=([^&]+)/;

  const urlMatch = decodeURIComponent(iframeString).match(regex);
  if (!urlMatch) {
    return '';
  }
  const [, url] = urlMatch;
  return url;
}

export {
  getYoutubeVideoThumbnailUrl,
  getVimeoVideoThumbnailUrl,
  getVideoThumbnailUrl,
  getVideoUrlFromIframe
};
